
import { GET_DETAIL_HKI_REQUEST, GET_DETAIL_HKI_SUCCESS, GET_DETAIL_HKI_FAILURE } from "./type";
import { showDetailHKIAPI } from '../../config/API'


export const getDetailHKIRequest = () => ({
    type: GET_DETAIL_HKI_REQUEST
})

export const getDetailHKISuccess = (payload) => ({
    type: GET_DETAIL_HKI_SUCCESS,
    payload
})

export const getDetailHKIFailure = (payload) => ({
    type: GET_DETAIL_HKI_FAILURE,
    payload
})

export const GET_DETAIL_HKI =  (id) => async (dispatch) => {
    try {
        dispatch(getDetailHKIRequest())
        const data = await showDetailHKIAPI(id)
        dispatch(getDetailHKISuccess(data))
        return true
    } catch (err) {
        dispatch(getDetailHKIFailure(err))
        return false
    }
}