import React from "react";
import "../styling.css";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import PendirianBadanUsaha from "./PendirianBadanUsaha";
import HKI from "./HKI";

const Home = () => {
  const isLocation = localStorage.getItem("nav");

  return (
    <div style={{ backgroundColor: "#F7FAFC", minHeight: "100vh", width: "100%" }}>
      <Navbar />
      {isLocation === "hki" ? <HKI /> : <PendirianBadanUsaha />}
      <Footer />
    </div>
  );
};

export default Home;
