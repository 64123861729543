import { DocBase } from "./base";
import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
import { TitleBar } from "./title-bar";
import DefaultDocument from "./DefaultDocument";
import "./style.css";
DocumentEditorContainerComponent.Inject(Toolbar);

class WordProcessor extends DocBase {
  constructor() {
    super(...arguments);
    this.hostUrl =
      "https://ej2services.syncfusion.com/production/web-services/";
    this.onLoadDefault = async () => {
      this.container.documentEditor.open(
        JSON.stringify(await DefaultDocument(this.props.params, this.props.type))
      );
      this.container.documentEditor.documentName = this.props.type + '_' + this.props.params;
      this.titleBar.updateDocumentTitle();
      this.container.documentChange = () => {
        this.titleBar.updateDocumentTitle();
        this.container.documentEditor.focusIn();
      };
    };
  }
  rendereComplete() {
    this.container.serviceUrl = this.hostUrl + "api/documenteditor/";
    this.container.documentEditor.pageOutline = "#E0E0E0";
    this.container.documentEditor.acceptTab = true;
    this.container.documentEditor.resize();
    this.titleBar = new TitleBar(
      document.getElementById("documenteditor_titlebar"),
      this.container.documentEditor,
      true
    );
    this.onLoadDefault();
  }
  render() {
    return (
      <div className="control-pane">
        <div className="control-section">
          <div id="documenteditor_titlebar" className="e-de-ctn-title"></div>
          <div id="documenteditor_container_body">
            <DocumentEditorContainerComponent
              id="container"
              ref={(scope) => {
                this.container = scope;
              }}
              style={{ display: "block" }}
              height={"95vh"}
              enableToolbar={true}
              locale="en-US"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default WordProcessor;
