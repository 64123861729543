import React from "react";
import "./Header.css";

const Header = (props) => {
  return (
    <div className="container-md pt-md-4 px-0 mb-5">
      <div className={props.type === "hki" ? "header-hki" : "header"}>
        <h1 style={{fontWeight: 'bold', color: 'white', marginBottom: 0}}>
          {props.type === "hki" ? "Hak Kekayaan Intelektual" : "Pendirian Badan Usaha"}
        </h1>
        <div className="search">
          <input type="text" placeholder="Cari Disini" onChange={props.input} />
          <span className="icon-src"></span>
        </div>
      </div>
    </div>
  );
};

export default Header;
