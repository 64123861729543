
import { GET_HKI_REQUEST, GET_HKI_SUCCESS, GET_HKI_FAILURE } from "./type";
import { showAllHKIAPI } from '../../config/API'


export const getHKIRequest = () => ({
    type: GET_HKI_REQUEST
})

export const getHKISuccess = (payload) => ({
    type: GET_HKI_SUCCESS,
    payload
})

export const getHKIFailure = (payload) => ({
    type: GET_HKI_FAILURE,
    payload
})

export const GET_HKI =  () => async (dispatch) => {
    try {
        dispatch(getHKIRequest())
        const data = await showAllHKIAPI()
        dispatch(getHKISuccess(data))
        return true
    } catch (err) {
        dispatch(getHKIFailure(err))
        return false
    }
}