
import { GET_DETAIL_HKI_REQUEST, GET_DETAIL_HKI_SUCCESS, GET_DETAIL_HKI_FAILURE } from '../action/type'

const initialState = {
  loading: false,
  data: {},
  error: null

}

// eslint-disable-next-line
export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DETAIL_HKI_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_DETAIL_HKI_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: payload
      });
    case GET_DETAIL_HKI_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload

      });
    default:
      return state

  }
}