import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "../../src/assets/image/logo.svg";
import { authService } from "../config";
import "./Navbar.css";

const Navbar = () => {
  // const [activeNav, setActiveNav] = useState("pbu");
  const route = useHistory();
  const user = authService.getCurrentUser();

  useEffect(() => {
    if (user && user.accessToken) {
      // console.log("welcome");
    } else {
      route.push("/login");
    }
  }, [user, route]);

  const handleLogout = () => {
    authService.logout();
    route.push("/login");
  };

  return (
    <div className="nav py-1">
      <div className="container nav-wrapper">
        <div className="nav-mobile d-flex align-items-center justify-content-between w-100">
          <Link to="/">
            <img
              src={Logo}
              alt="Logo Kontrak Hukum"
              height={64}
              className="img"
            />
          </Link>
        
          <div className="logout-mobile" onClick={handleLogout}>
            <span className="ml-3">Logout</span>
          </div>
        </div>

        <div className="nav-item">
          <div className="nav-link">
            <Link to="/" style={{ textDecoration: "none" }}>
              <p
                className={
                  localStorage.getItem("nav") === "hki"
                    ? "navItem"
                    : "navItem-active"
                }
                onClick={() => localStorage.setItem("nav", "")}
              >
                Pendirian Badan Usaha
              </p>
            </Link>
            
            <Link to="/" style={{ textDecoration: "none" }}>
              <p
                className={
                  localStorage.getItem("nav") === "hki"
                    ? "navItem-active"
                    : "navItem"
                }
                onClick={() => localStorage.setItem("nav", "hki")}
              >
                Hak Kekayaan Intelektual
              </p>
            </Link>
          </div>

          <div className="logout ml-sm-auto ml-md-5" onClick={handleLogout}>
            <span className="ml-3">Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;