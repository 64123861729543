import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes } from "./config";
import Store from './redux/store';
import { Provider } from "react-redux";

function App() {
  return (
    <div className="App">
      <Provider store={Store}>
      <Routes />
    </Provider>
    </div>
  );
}

export default App;
