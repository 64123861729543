import axios from "axios";
import { authHeader } from "..";

//other api
export const showAllAPI = async () => {
  const inst = await axios.get(`${process.env.REACT_APP_API_URL}/see`, {
    headers: authHeader(),
  });
  return inst.data;
};

export const showDetailAPI = async (p) => {
  const inst = await axios.get(`${process.env.REACT_APP_API_URL}/detail/${p}`, {
    headers: authHeader(),
  });
  return inst.data.information_value;
};

export const editDocAPI = async (p) => {
  const inst = await axios.get(`${process.env.REACT_APP_API_URL}/edit/${p}`, {
    headers: authHeader(),
  });
  return inst.data;
};

export const editSKdocAPI = async (p) => {
  const inst = await axios.get(
    `${process.env.REACT_APP_API_URL}/edit-sk/${p}`,
    {
      headers: authHeader(),
    }
  );
  return inst.data;
};

export const editSPdkDocAPI = async (p) => {
  const inst = await axios.get(
    `${process.env.REACT_APP_API_URL}/edit-sp-dk/${p}`,
    {
      headers: authHeader(),
    }
  );
  return inst.data;
};

export const editSPsmDocAPI = async (p) => {
  const inst = await axios.get(
    `${process.env.REACT_APP_API_URL}/edit-sp-sm/${p}`,
    {
      headers: authHeader(),
    }
  );
  return inst.data;
};
export const findKBLI = async (p) => {
  const inst = await axios.get(
    `${process.env.REACT_APP_API_URL}/cek-kbli/${p}`,
    {
      headers: authHeader(),
    }
  );
  return inst.data;
};

//api for hki
export const showAllHKIAPI = async () => {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/hki`, {
    headers: authHeader(),
  });
  return data;
};

export const showDetailHKIAPI = async (p) => {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/hki/${p}`, {
    headers: authHeader(),
  });
  return data;
};

export const docEditKuasaHukumPendaftaranMerek = async (p)=> {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/hki/edit-surat-kuasa-pendaftaran-merek/${p}`, {
    headers: authHeader(),
  });
  return data;
}
export const docEditKuasaHukumPerpanjanganMerek = async (p)=> {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/hki/edit-surat-kuasa-perpanjangan-merek/${p}`, {
    headers: authHeader(),
  });
  return data;
}
export const docEditKuasaHukumHakCipta = async (p)=> {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/hki/edit-surat-kuasa-hak-cipta/${p}`, {
    headers: authHeader(),
  });
  return data;
}
export const docEditKuasaHukumDesainIndustri = async (p)=> {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/hki/edit-surat-kuasa-desain-industri/${p}`, {
    headers: authHeader(),
  });
  return data;
}
