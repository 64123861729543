import React from 'react'

const Footer = () => {
  return (
    <footer style={{backgroundColor: '#f7fafc'}}>
      <p style={{fontSize: '14px', color: '#9CAEC4', padding: '20px 0'}}>
        Copyright &#169;2021 PT. LEGAL TEKNO DIGITAL. All rights reserved.
      </p>
    </footer>
  )
}

export default Footer
