import React, { useEffect } from "react";
import WordProcessor from "../../components/WordProcessor";

const DocEditHKI = (props) => {
  let linkroute = window.location+ '';
  // const location = useLocation();
  // const type = location.type;

  useEffect(() => {
    window.onbeforeunload = function () {
      return "Want to save your changes?";
    };
  }, [props]);
  
  const handleBack = () => {
    const url = []
    url.push(linkroute.split('/')[0])
    url.push('//')
    url.push(linkroute.split('/')[2])

    // console.log(url.join(""))

    // history.goBack();
    window.open(url.join("") + "/detail-hki/" + props.match.params.pId, "_self")
  };

  return (
    <div
      style={{ width: "100%", height: "100vh", position: "absolute", top: 0 }}
    >
      <div style={{ height: "5vh", position: "fixed", width: "200px" }}>
        <div
          className='btn-word'
          onClick={handleBack}
        >
          BACK
        </div>
      </div>
      <WordProcessor params={props.match.params.pId} type={props.match.params.type} />
    </div>
  );
};

export default DocEditHKI;
