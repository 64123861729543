import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { API } from "../config";
//import CustomModal from "../components/CustomModal";
//import Spinner from "../components/Spinner";

const Detail = (props) => {
  const [DataPerusahaan, setDataPerusahaan] = useState([]);
  const [DataPsaham, setDataPsaham] = useState([]);
  const [DataPemodalan, setDataPemodalan] = useState([]);
  //const [openModal, setOpenModal] = useState(false);
  // const [modalTitle, setModalTitle] = useState("");
  // const [loadModal, setLoadModal] = useState(false);
  // const [ModalData, setmodalData] = useState("");
  const [key, setKey] = useState('informasi');

  function toggleActive(id) {
    if (key === id) {
      setKey(null);
    } else {
      setKey(id);
    }
  }
  
  let history = useHistory();

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await API.showDetailAPI(props.match.params.pId);
        const data = JSON.parse(res);
        const perusahaan = [];
        const pemodalan = [];
        const pSaham = [];
        for (const i in data["informasi-pemegang-saham-perusahaan"]) {
          pSaham.push(data["informasi-pemegang-saham-perusahaan"][i]);
        }
        Object.keys(data["informasi-pemodalan"]).forEach((key) => {
          pemodalan[key] = data["informasi-pemodalan"][key];
        });
        Object.keys(data["informasi-perusahaan"]).forEach((key) => {
          perusahaan[key] = data["informasi-perusahaan"][key];
        });
        setDataPerusahaan(perusahaan);
        setDataPemodalan(pemodalan);
        setDataPsaham(pSaham);
      } catch (err) {
        if (err.response) {
          console.log(err.response.status);
        } else {
          console.log(err);
        }
      }
    };
    getData();
  }, [props]);

  // const save = () => {
  //   return window.location.assign(
  //     `${process.env.REACT_APP_API_URL}/form-pt/${props.match.params.pId}`
  //   );
  // };

  const edit = () => {
    const pass = {
      pathname: `/doc/${props.match.params.pId}/pendirian-pt`,
    };
    history.push(pass);
  };

  const editsk = () => {
    const pass = {
      pathname: `/doc/${props.match.params.pId}/surat-kuasa`,
    };
    history.push(pass);
  };

  const editspdk = () => {
    const pass = {
      pathname: `/doc/${props.match.params.pId}/surat-pernyataan-direktur-komisaris`,
    };
    history.push(pass);
  };

  const editspsm = () => {
    const pass = {
      pathname: `/doc/${props.match.params.pId}/surat-pernyataan-setor-modal`,
    };
    history.push(pass);
  };

  function formatNumber(param) {
    var IntFormat = parseInt(param);
    return IntFormat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  // const openKBLI = async (code, title) => {
  //   setLoadModal(true);
  //   if (code) {
  //     setOpenModal(true);
  //     setModalTitle(title);
  //     try {
  //       const res = await API.findKBLI(code);
  //       await setmodalData(res.uraian);
  //       await setLoadModal(false);
  //     } catch (err) {
  //       await setmodalData("internal server error");
  //       await setLoadModal(false);
  //       console.log(err);
  //     }
  //     alert(code);
  //   } else {
  //     setOpenModal(true);
  //     setModalTitle('KBLI Tidak ditemukan');
  //     setmodalData("Kode KBLI tidak ditemukan dalam database, silahkan lengkapi data pada proses pemesanan")
  //     setLoadModal(false);
  //   }
  // };

  // const closeKBLI = () => {
  //   setOpenModal(false);
  // };

  return (
    <div style={{ backgroundColor: "#f7fafc", height: "100%", width: "100%" }}>
      {/* <CustomModal isOpen={openModal} closeModal={closeKBLI} title={modalTitle}>
        {loadModal ? (
          <>
            <Spinner heights={50}/>
          </>
        ) : (
          ModalData
        )}
      </CustomModal> */}
      <Navbar />
      <div style={{backgroundColor: "#f7fafc", width: '100%'}}>
        <div className="container detail mt-4 px-0">
          <div className="detail-header">
            <div className="btn-back" onClick={() => history.push("/")}></div>
            <h1>{DataPerusahaan["nama-pt-prioritas"]}</h1>
            <p>{DataPerusahaan["lokasi"]}</p>
            <p>{DataPerusahaan["email-perusahaan"]}</p>
            <p>{DataPerusahaan["no-telp-kantor"]}</p>
          </div>
          <div className="card-detail px-3 px-lg-4 ">
            <p className="title py-4">Edit Dokumen</p>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                width: "calc(100% - 64px)",
                marginBottom: 16,
              }}
            >
              <button className="btn-mail" onClick={editsk}>
                <span className="ml-4">Surat Kuasa</span>
              </button>
              <button className="btn-company" onClick={edit}>
                <span className="ml-4">Minuta Akta</span>
              </button>
              <button className="btn-people text-left" onClick={editspdk}>
                <span className="ml-4">Surat Pernyataan Direktur dan Komisaris</span>
              </button>
              <button className="btn-invest" onClick={editspsm}>
                <span className="ml-4">Surat Pernyataan Setor Modal</span>
              </button>
            </div>

            <Accordion className='mt-3 w-100 pb-3 accordion-wrapper' defaultActiveKey="informasi">
              <Card className="accordion-item mb-3">
                <Accordion.Toggle onClick={() => toggleActive('informasi')} eventKey='informasi' className={key === "informasi" ? 'toggle-active' : 'toggle'}>
                  Informasi Perusahaan
                  <span className="icon-arrow-down" style={{transform: key === 'informasi' && 'rotate(180deg)'}}/>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='informasi'>
                  <Card.Body className="pt-0" style={{borderRadius: '16px'}}>
                    <Table responsive="md">
                      <tbody>
                        <tr>
                          <td className="table-data">Nama PT Prioritas</td>
                          <td>: {DataPerusahaan["nama-pt-prioritas"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">Nama PT Alternatif 1</td>
                          <td>: {DataPerusahaan["nama-pt-alternatif-1"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">Nama PT Alternatif 2</td>
                          <td>: {DataPerusahaan["nama-pt-alternatif-2"]}</td>
                        </tr>
                    
                        <tr>
                          <td className="table-data">Karyawan Laki-Laki</td>
                          <td>: {DataPerusahaan["karyawan-l"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">Karyawan Perempuan</td>
                          <td>: {DataPerusahaan["karyawan-p"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">Lokasi</td>
                          <td>: {DataPerusahaan["lokasi"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">Gedung</td>
                          <td>: {DataPerusahaan["nama-gedung"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">Lantai</td>
                          <td>: {DataPerusahaan["lantai"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">RT</td>
                          <td>: {DataPerusahaan["rt"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">RW</td>
                          <td>: {DataPerusahaan["rw"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">Kelurahan</td>
                          <td>: {DataPerusahaan["kelurahan"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">Kecamatan</td>
                          <td>: {DataPerusahaan["kecamatan"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">Kota/Kab.</td>
                          <td>: {DataPerusahaan["kota"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">Provinsi</td>
                          <td>: {DataPerusahaan["provinsi"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">Kode Pos</td>
                          <td>: {DataPerusahaan["kode-pos"]}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="accordion-item mb-3">
                <Accordion.Toggle onClick={() => toggleActive('saham')} eventKey='saham' className={key === 'saham' ? 'toggle-active' : 'toggle'}>
                  Pemegang Saham
                  <span className="icon-arrow-down" style={{transform: key === 'saham' && 'rotate(180deg)'}}/>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='saham'>
                  <Card.Body className="py-0">
                    <Table responsive="md">
                      {DataPsaham.map((e, i) => {
                        const nama = e["nama"];
                        const jenis = e[`jenis-pemegang-saham-${i + 1}`];
                        const peran = e[`peran-kepengurusan-${i + 1}`];
                        const ktp = e["no-ktp"];
                        const npwp = e["no-npwp"];
                        const alamat = e["alamat-lengkap"];
                        const lebarSaham = e["jumlah-lembar-saham"];
                        const persen = e["persentase-saham"];
                        const totalModal = e["total-modal"];
                        return (
                          <tbody key={i}>
                            <tr>
                              <td>
                                <strong>Pemegang Saham {`${i + 1}`}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td className="table-data">Nama</td>
                              <td>: {nama}</td>
                            </tr>
                            <tr>
                              <td className="table-data">Jenis Pemegang Saham</td>
                              <td>: {jenis}</td>
                            </tr>
                            <tr>
                              <td className="table-data">Peran Pemegang Saham</td>
                              <td>: {peran}</td>
                            </tr>
                            <tr>
                              <td className="table-data">Nomer KTP</td>
                              <td>: {ktp}</td>
                            </tr>
                            <tr>
                              <td className="table-data">Nomer NPWP</td>
                              <td>: {npwp}</td>
                            </tr>
                            <tr>
                              <td className="table-data">Alamat</td>
                              <td>: {alamat}</td>
                            </tr>
                            <tr>
                              <td className="table-data">Lebar Saham</td>
                              <td>: {lebarSaham}</td>
                            </tr>
                            <tr>
                              <td className="table-data">Persentase Saham</td>
                              <td>: {persen}</td>
                            </tr>
                            <tr>
                              <td className="table-data">Total Modal</td>
                              <td>: Rp {formatNumber(totalModal)},00</td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="accordion-item mb-3">
                <Accordion.Toggle onClick={() => toggleActive('pemodalan')} eventKey='pemodalan' className={key === 'pemodalan' ? 'toggle-active' : 'toggle'}>
                  Informasi Pemodalan
                  <span className="icon-arrow-down" style={{transform: key === 'pemodalan' && 'rotate(180deg)'}}/>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='pemodalan'>
                  <Card.Body className="py-0">
                    <Table responsive="md">
                      <tbody>
                        <tr>
                          <td className="table-data">Nilai Per Lembar Saham</td>
                          <td>
                            : Rp{" "}
                            {formatNumber(
                              DataPemodalan["nilai-per-lembar-saham"]
                            )}
                            ,00
                          </td>
                        </tr>
                        <tr>
                          <td className="table-data">Total Lembar Saham</td>
                          <td>
                            : {formatNumber(DataPemodalan["total-lembar-saham"])}
                          </td>
                        </tr>
                        <tr>
                          <td className="table-data">Modal Dasar</td>
                          <td>
                            : Rp {formatNumber(DataPemodalan["modal-dasar"])},00
                          </td>
                        </tr>
                        <tr>
                          <td className="table-data">Modal Disetor</td>
                          <td>
                            : Rp {formatNumber(DataPemodalan["modal-disetor"])},00
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Detail;
