import { combineReducers } from "redux";
import GetHKIData from './GetHKIDataReducer'
import GetDetailHKIData from "./GetDetailHKIDataReducer";
import Store from './storeReducer'


export default combineReducers({
    GetHKIData,
    GetDetailHKIData,
    Store,
})