import React, { useEffect, useState } from "react";
import "../styling.css";
import { useHistory } from "react-router";
import Header from "../../components/Header";
import moment from "moment";
import { API } from "../../config";
import Spinner from "../../components/Spinner";

//material ui table
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { TableHead } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  sticky: {
    position: "sticky",
    right: 0,
    background: "#fff",
    color: "white",
  },
});

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const PendirianBadanUsaha = () => {
  const [Data, setData] = useState([]);
  const [input, setInput] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const route = useHistory();
  const classes = useStyles();

  const handleOpen = (param) => {
    route.push(`/detail-pt/${param}`);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await API.showAllAPI();
        setData(res.map((data) => data));
        setIsLoading(false)
      } catch (error) {
        if (error.response) {
          console.log(error.response.status);
        } else console.error();
      }
    };
    getData();
  }, []);

  // const sortedActivities = Data.map((data) => data)
  const sortedData = Data.sort((a, b) => new Date(b.date_created) - new Date(a.date_created))

  const rows = () => {
    const row = [];
    sortedData.map((data) => {
      const jsonData = JSON.parse(data.information_value);
      return row.push({
        id: data.id,
        project_id: data.project_id,
        nama_pt_prioritas:
          jsonData["informasi-perusahaan"]["nama-pt-prioritas"],
        bidang_usaha: jsonData["informasi-perusahaan"]["bidang-usaha-utama"],
        date_created: moment(new Date(data.date_created)).format("DD/MM/YYYY"),
        last_updated: moment(new Date(data.last_updated)).format("DD/MM/YYYY"),
      });
    });

    if (input == null) {
      return row;
    } else {
      return row.filter((data) =>
        data["nama_pt_prioritas"].toLowerCase().includes(input)
      );
    }
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows().length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value.toLowerCase());
  };

  return (
    <>
      <Header data={Data} input={handleInputChange} />
      <div className="home">
        <div className="container home-wrapper p-3 p-lg-4">
          <TableContainer
            style={{
              border: "1px solid #c5d1de",
              borderRadius: 8,
            }}
          >
            <Table
              className={classes.table}
              aria-label="simple table"
              style={{ tableLayout: "fixed" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell width={60}>ID</TableCell>
                  <TableCell align="left" width={300}>
                    Nama PT Prioritas
                  </TableCell>
                  <TableCell align="left" width={130}>
                    Tanggal Dibuat
                  </TableCell>
                  <TableCell align="left" width={140}>
                    Tanggal Update
                  </TableCell>
                  <TableCell align="left" width={120} className={classes.sticky}>
                    
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? rows().slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows()
                ).map((row) => (
                  <TableRow key={row.project_id} className="table-row">
                    <TableCell>{row.project_id}</TableCell>
                    <TableCell align="left">{row.nama_pt_prioritas}</TableCell>
                    <TableCell align="left">{row.date_created}</TableCell>
                    <TableCell align="left">{row.last_updated}</TableCell>
                    <TableCell
                      align="left"
                      className="sticky-cell"
                      component="th"
                      scope="row"
                    >
                      <div
                        className="btn-dtl"
                        onClick={() => handleOpen(row.project_id)}
                      >
                        Selengkapnya
                      </div>
                    </TableCell>
                  </TableRow>
                ))}

                {isLoading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={5} align="center">
                      {" "}
                        <Spinner heights={50}/> <br/> Menunggu Data ...
                    </TableCell>
                  </TableRow>
                )}

                {!isLoading && emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={5} align="center">
                      {" "}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      25,
                      50,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={rows().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default PendirianBadanUsaha;
