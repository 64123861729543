import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Detail from "../../page/Detail";
import DetailHKI from "../../page/HKIPage";
import DocEdit from "../../page/DocEdit";
import Home from "../../page/Home";
import Login from "../../page/Login";
import DocEditHKI from "../../page/HKIPage/DocEditHKI"

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        {/* <Redirect exact to="/login" /> */}
        <Route path="/detail-pt/:pId" component={Detail} />
        <Route path="/detail-hki/:pId" component={DetailHKI} />
        <Route path="/doc/hki/:pId/:type" component={DocEditHKI} />
        <Route path="/doc/:pId/:type" component={DocEdit} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
};

export default Routes;
