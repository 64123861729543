import React, { useEffect, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { useHistory } from "react-router";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { useSelector, useDispatch } from "react-redux";
import { GET_DETAIL_HKI } from '../../redux/action/GetDetailHKIDataAction'


const DetailHKI = (props) => {
  const [key, setKey] = useState('informasi');
  const dispatch = useDispatch()
  const data = useSelector((state) => state.GetDetailHKIData.data)
  const history = useHistory();
  const { dataMerk } = useSelector((state) => state.Store)


  useEffect(() => {
    dispatch(GET_DETAIL_HKI(props.match.params.pId))
    // eslint-disable-next-line
  }, []);
  function toggleActive(id) {
    if (key === id) {
      setKey(null);
    } else {
      setKey(id);
    }
  }
  useEffect(() => {
    if (!data.project_informations) {
      return
    } else {
      dispatch({
        type: 'SET_DETAIL_MEREK',
        payload: {}
      })
      data.project_informations.forEach(el => {
        if (el.information_code === "form-identitas-merek") {
          dispatch({
            type: 'SET_DETAIL_MEREK',
            payload: JSON.parse(el.information_value)["informasi-merek"]
          })
        }
      })
    }
    // eslint-disable-next-line
  }, [data])



  const editSuratKuasa = () => {
    let pass = {}
    if (/pendaftaran merek/.test(data.name.toLowerCase())) {
      pass = {
        pathname: `/doc/hki/${props.match.params.pId}/edit-surat-kuasa-pendaftaran-merek`,
      }
    } else if (/hak cipta/.test(data.name.toLowerCase())) {
      pass = {
        pathname: `/doc/hki/${props.match.params.pId}/edit-surat-kuasa-hak-cipta`,
      }
    } else if (/desain industri/.test(data.name.toLowerCase())) {
      pass = {
        pathname: `/doc/hki/${props.match.params.pId}/edit-surat-kuasa-desain-industri`,
      }
    }
    history.push(pass);
  };

  const ButtonKuasaHukum = () => {
    if (!data.name) {
      return null
    } else {
      if (/pengecekan merek/.test(data.name.toLowerCase())) {
        return null
      } else {
        return (
          <>
          <p className="title py-4">Edit Dokumen</p>
          <button className="btn-mail col-2"
            onClick={() => editSuratKuasa()}>
            <span className="ml-1">Surat Kuasa</span>
          </button>
          </>
        )
      }
    }
  }

  return (
    <div style={{ backgroundColor: "#f7fafc", height: '100%', width: '100%' }}>
      <Navbar />
      <div style={{ backgroundColor: "#f7fafc", width: '100%' }}>
        <div className="container detail mt-4 px-0">
          <div className="detail-header-hki">
            <div className="btn-back" onClick={() => history.push("/")}></div>
            <h1>{dataMerk["nama"]}</h1>
            <p>{dataMerk["nama-pemilik"]}</p>
          </div>

          <div className="card-detail px-3 px-lg-4 ">
            <ButtonKuasaHukum />

            <Accordion className='mt-3 w-100 pb-3 accordion-wrapper' defaultActiveKey="informasi">
              <Card className="accordion-item mb-3">
                <Accordion.Toggle onClick={() => toggleActive('informasi')} eventKey='informasi' className={key === "informasi" ? 'toggle-active' : 'toggle'}>
                  Informasi Merk
                  <span className="icon-arrow-down" style={{ transform: key === 'informasi' && 'rotate(180deg)' }} />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='informasi'>
                  <Card.Body className="pt-0" style={{ borderRadius: '16px' }}>
                    <Table responsive="md">
                      <tbody>
                        <tr>
                          <td className="table-data">Nama Merek</td>
                          <td>: {dataMerk["nama"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">Jenis Kepemilikan</td>
                          <td>: {dataMerk["jenis-pemilik"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">Keterangan Merek</td>
                          <td>: {dataMerk["keterangan"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">Warna Merek</td>
                          <td>: {dataMerk["warna"]}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="accordion-item mb-3">
                <Accordion.Toggle onClick={() => toggleActive('pemilik')} eventKey='pemilik' className={key === 'pemilik' ? 'toggle-active' : 'toggle'}>
                  Pemilik Merek
                  <span className="icon-arrow-down" style={{ transform: key === 'pemilik' && 'rotate(180deg)' }} />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey='pemilik'>
                  <Card.Body className="py-0">
                    <Table responsive="md">
                      <tbody>
                        <tr>
                          <td className="table-data">Nama Pemilik</td>
                          <td>: {dataMerk["nama-pemilik"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">No KTP</td>
                          <td>: {dataMerk["no-ktp"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">No NPWP</td>
                          <td>: {dataMerk["no-npwp"]}</td>
                        </tr>
                        <tr>
                          <td className="table-data">Alamat</td>
                          <td>: {dataMerk["alamat"]}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DetailHKI;
