
import { API } from "../../config";

const DefaultDocument = async (p, type) => {
  let res = "";
  if (type === "pendirian-pt") {
    res = await API.editDocAPI(p);
  } else if (type === "surat-kuasa") {
    res = await API.editSKdocAPI(p);
  } else if (type === "surat-pernyataan-direktur-komisaris") {
    res = await API.editSPdkDocAPI(p);
  } else if (type === "surat-pernyataan-setor-modal") {
    res = await API.editSPsmDocAPI(p);
  } else if (type === "edit-surat-kuasa-pendaftaran-merek") {
    res = await API.docEditKuasaHukumPendaftaranMerek(p)
  } else if (type === "edit-surat-kuasa-perpanjangan-merek") {
    res = await API.docEditKuasaHukumPerpanjanganMerek(p)
  } else if (type === "edit-surat-kuasa-hak-cipta") {
    res = await API.docEditKuasaHukumHakCipta(p)
  } else if (type === "edit-surat-kuasa-desain-industri") {
    res = await API.docEditKuasaHukumDesainIndustri(p)
  }
  
  const Style = res.style;
  return {
    //default 1 inch = 72 px
    sections: [
      {
        sectionFormat: res.section,
        blocks: res.data, //data default nanti masuk ke sini
        headersFooters: {
          header: res.header
        } //buat setting headers dan footers, tp masih belom saya pelajari lebih lanjut
      },
    ],
    //end section
    paragraphFormat: {
      afterSpacing: 8.0,
      lineSpacing: 1.0791666507720947,
      lineSpacingType: "Multiple",
    },
    lists: [{ listId: 0, abstractListId: 0 }],
    abstractLists: [
      {
        abstractListId: 0,
        levels: [
          {
            startAt: 1,
            restartLevel: 0,
            listLevelPattern: "Arabic",
            followCharacter: "Tab",
            numberFormat: "%1.",
            characterFormat: { bold: true, italic: false },
            paragraphFormat: { leftIndent: 18.0, firstLineIndent: -18.0 },
          },
          {
            startAt: 1,
            restartLevel: 1,
            listLevelPattern: "Arabic",
            followCharacter: "Tab",
            numberFormat: "%1.%2.",
            characterFormat: { bold: false, italic: true },
            paragraphFormat: {
              leftIndent: 39.599998474121094,
              firstLineIndent: -21.600000381469727,
            },
          },
          {
            listLevelPattern: "Bullet",
            followCharacter: "Tab",
            numberFormat: "",
            characterFormat: { fontFamily: "Symbol" },
            paragraphFormat: {
              leftIndent: 61.200000762939453,
              firstLineIndent: -25.200000762939453,
            },
          },
          {
            startAt: 1,
            restartLevel: 3,
            listLevelPattern: "Arabic",
            followCharacter: "Tab",
            numberFormat: "%1.%2.%3.%4.",
            paragraphFormat: {
              leftIndent: 86.4000015258789,
              firstLineIndent: -32.400001525878906,
            },
          },
          {
            startAt: 1,
            restartLevel: 4,
            listLevelPattern: "Arabic",
            followCharacter: "Tab",
            numberFormat: "%1.%2.%3.%4.%5.",
            paragraphFormat: {
              leftIndent: 111.59999847412109,
              firstLineIndent: -39.599998474121094,
            },
          },
          {
            startAt: 1,
            restartLevel: 5,
            listLevelPattern: "Arabic",
            followCharacter: "Tab",
            numberFormat: "%1.%2.%3.%4.%5.%6.",
            paragraphFormat: {
              leftIndent: 136.80000305175781,
              firstLineIndent: -46.799999237060547,
            },
          },
          {
            startAt: 1,
            restartLevel: 6,
            listLevelPattern: "Arabic",
            followCharacter: "Tab",
            numberFormat: "%1.%2.%3.%4.%5.%6.%7.",
            paragraphFormat: { leftIndent: 162.0, firstLineIndent: -54.0 },
          },
          {
            startAt: 1,
            restartLevel: 7,
            listLevelPattern: "Arabic",
            followCharacter: "Tab",
            numberFormat: "%1.%2.%3.%4.%5.%6.%7.%8.",
            paragraphFormat: {
              leftIndent: 187.19999694824219,
              firstLineIndent: -61.200000762939453,
            },
          },
          {
            startAt: 1,
            restartLevel: 8,
            listLevelPattern: "Arabic",
            followCharacter: "Tab",
            numberFormat: "%1.%2.%3.%4.%5.%6.%7.%8.%9.",
            paragraphFormat: { leftIndent: 216.0, firstLineIndent: -72.0 },
          },
        ],
      },
    ],
    styles: Style, //styling dokumen dari backend masuk ke sini
  };
};

export default DefaultDocument;
