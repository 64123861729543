import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import imgSide from "../assets/image/img-side.svg";
import email from "../assets/icon/small-mail.svg";
import pwd from "../assets/icon/lock.svg";
import eye from "../assets/icon/eye.svg";
import logo from "../assets/KH.svg";
import { authService } from "../config";

const Login = () => {
  const route = useHistory();
  const [showPass, setShowPass] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [Loading, setLoading] = useState(false);
  const user = authService.getCurrentUser();

  useEffect(() => {
    if (user && user.accessToken) {
      route.goBack();
    } else {
      // console.log("login page");
    }
  }, [user, route]);
  
  const handleLogin = () => {
    setLoading(true);
    if (username === "" || password === "") {
      setErrMsg("username atau password tidak boleh kosong");
      setLoading(false);
    } else {
      authService.login(username, password)
        .then(() => {
          route.push("/");
        })
        .catch((err) => {
          setErrMsg(err.response.data.message);
          setLoading(false);
        });
    }
  };
  
  return (
    <div className="login">
      <div className="container">
        <div className="row mx-2 align-items-center justify-content-center d-flex login-wrap">
          <div className="col-lg-5 form-card">
            <img src={logo} alt="Logo Kontrak Hukum" className="img-login" />
            <div className="title">Masuk Kontrak Hukum</div>

            <div className="input-wrapper">
              <img src={email} alt="email" className="icon-email"/>
              <input
                placeholder="username atau email"
                className="inpt-login-email"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            
            <div className="input-wrapper">
              <img src={pwd} alt="password" className="icon-pw"/>
              <input
                type={showPass ? 'text' : 'password'}
                placeholder="password"
                className="inpt-login-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <img
                src={eye}
                className="icon-eye"
                alt=""
                onClick={() => setShowPass(!showPass)} />
            </div>

            {errMsg ? <div className="err">{errMsg}</div> : <></>}
            {/* <p className="lupa-pwd">Lupa Password</p> */}
            
            <button className={Loading ? 'btn-login btn-loading' : 'btn-login'} onClick={handleLogin}>
              {Loading ? 'Loading...' : 'Masuk'}
            </button>
          </div>

          <div className="col-lg-3 img-side">
              <img src={imgSide} alt="background"/>
              <h2>KH Document Generator</h2>
              <p>Memproses dokumen pendirian badan usaha dengan mudah</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
