import { SET_DETAIL_MEREK, SET_EXPAND } from '../action/type'

const initialState = {
    dataMerk: {},
    expand: ''

}

// eslint-disable-next-line
export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_DETAIL_MEREK:
            return {...state, dataMerk: payload}
        case SET_EXPAND:
            return {...state, expand: payload}


        default:
            return state

    }
}